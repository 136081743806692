<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <!-- Esto corresponde a un abono sobre un servicio por pagar -->
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Abonar Pago #{{ id_payment }}</b></h2>
                        <label for="date" class="form-label"><b>Monto impago : 
                            {{ getformatCurrency(dataForm.payment.unpaid_amount,
                                    dataForm.payment.initials) }} {{ dataForm.payment.initials }}
                            </b></label>
                        <p>Permite registrar un abono total sobre un pago a proveedor.
                        </p>
                        <p>Abono asociado al cliente <b>{{ clientName }}</b></p>
                    </div>
                    <div class="row pt-4 px-1">
                        <div class="col-4 mb-4">
                            <label for="date" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                            <span v-if="dataForm.date.err" class="text-danger">Debe seleccionar una fecha</span>
                        </div>




                    </div>
                    <div class="row pt-1 px-1">

                        <div class="col-4 mb-4">
                            <label for="currenci" class="form-label">Divisa</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currenci.value"
                                @change="formatAmountAndUpdate(dataForm.mount, dataForm.currenci)">
                                <option v-for="currenci in currencies" :value="currenci.id_currency">{{ currenci.name }}
                                </option>
                            </select>
                        </div>

                        <div class="col-4 mb-4">
                            <label for="mount" class="form-label">Monto</label>
                            <input class="form-control" :class="{ 'border-danger': dataForm.mount.err }" id="mount"
                                placeholder="" v-model="dataForm.mount.value_formated" @blur="handlerBlur"
                                @input="formatAmountAndUpdate(dataForm.mount, dataForm.currenci)">
                            <span v-if="dataForm.mount.err" class="text-danger">Debe ingresar un
                                monto</span>
                        </div>

                    </div>
                    <div class="row pt-4 px-1" v-if="newBank == false">
                        <div class="col-6 mb-4">
                            <label for="account-select">Selecciona una cuenta bancaria:</label>
                            <select class="form-select" id="account-select" v-model="selectedAccount"
                                @change="setAccountDetails">
                                <option v-for="account in clientBank" :key="account.id_client_bank" :value="account">
                                    {{ account.bank_name }} - {{ account.account_type_name }} - {{
            account.account_number }}
                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="row pt-4 px-1" v-if="newBank == true">
                        <h6 class="text-black">Ingrese una cuenta bancaria</h6>
                        <div class="col-4 mb-4">
                            <label for="paymentMethod" class="form-label">Método de pago</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.paymentMethod.value">
                                <option v-for="paymentMethod in paymentMethodes" :value="paymentMethod.id">{{
            paymentMethod.name }}</option>
                            </select>
                        </div>
                        <div class="col-4 mb-4">
                            <label for="bank" class="form-label">Banco de origen</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.bank.value">
                                <option v-for="bank in banks" :value="bank.id_bank">{{ bank.name }}</option>
                            </select>
                        </div>
                        <div class="col-4 mb-4">
                            <label for="accountOrigin" class="form-label">Cuenta Origen</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.accountOrigin.err }" id="accountOrigin"
                                placeholder="" v-model="dataForm.accountOrigin.value" @blur="handlerBlur">
                            <span v-if="dataForm.accountOrigin.err" class="text-danger">Debe ingresar una cuenta
                                bancaria</span>
                        </div>
                    </div>
                    <div class="row pt-4 px-1">

                        <div class="col-4">
                            <label for="description" class="form-label">Observación</label>
                            <textarea type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.description.err }" id="description"
                                placeholder="Ingrese un mensaje" v-model="dataForm.description.value"
                                @blur="handlerBlur">
                            </textarea>
                            <p v-if="dataForm.description.err" class="text-danger">Debe ingresar una
                                observacion</p>
                        </div>
                    </div>
                    <hr>
                    <p>En caso de abonar en moneda distinta a la del pago a proveedor original, completar:</p>
                    <div class="row">
                        <div class="col-4 mb-4">
                            <label for="documentNumber" class="form-label">Tasa de cambio</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm3.exchangeRate.err }" id="exchangeRate"
                                placeholder="" v-model="dataForm3.exchangeRate.value" @blur="handlerBlur">
                            <span v-if="dataForm3.exchangeRate.err" class="text-danger">Debe ingresar un número de
                                documento</span>
                        </div>
                        <div class="col-4 mb-4">
                            <!-- Campo ignorado, se calcula en backend -->
                            <!--
                            <label for="valueMoney" class="form-label">Valor en moneda original de este abono</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm3.valueMoney.err }" id="valueMoney" placeholder=""
                                v-model="dataForm3.valueMoney.value_formated" @blur="handlerBlur">
                            <span v-if="dataForm3.valueMoney.err" class="text-danger">Debe ingresar un número de
                                documento</span>
                            -->
                        </div>
                    </div>
                    <hr>
                    <p>Adjuntar factura o documento que corresponda</p>
                    <div class="row">
                        <div class="col-4 mb-4">
                            <label for="documentNumber" class="form-label">Número comprobante pago</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm2.documentNumber.err }" id="documentNumber"
                                placeholder="" v-model="dataForm2.documentNumber.value" @blur="handlerBlur">
                            <span v-if="dataForm2.documentNumber.err" class="text-danger">Debe ingresar un número de
                                documento</span>
                        </div>


                        <div class="col-4 mb-4">
                            <label for="value-cot" class="form-label mb-0">Adjuntar Archivos</label>
                            <DropFile :files="dataForm2.files" />
                            <div class="col-12 mt-4">
                                <div v-for="file in dataForm2.files">
                                    <div class="text-dark row">
                                        <a class="col-6">
                                            {{ file.name }}
                                        </a>
                                        <div class="col-1" @click="showModal = true"><a
                                                class="dropdown-item text-primary" href="#"> <i
                                                    class="bi bi-eye px-2"></i></a></div>
                                        <div class="col-1" @click="deleteArchive(file)"><a
                                                class="dropdown-item text-danger" href="#"> <i
                                                    class="bi bi-trash px-2"></i></a></div>
                                    </div>

                                    <PreviewFile v-if="showModal" :blob="dataForm2.fileBlob"
                                        :mimeType="dataForm2.mime_type" :visible="showModal"
                                        @close="showModal = false" />
                                </div>
                            </div>
                            <span v-if="dataForm2.fileerr" class="text-danger">Debe adjuntar un documento</span>
                        </div>

                    </div>
                    <hr>

                    <div class="row">


                        <div class="text-center text-lg-end mt-4 pt-2">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="create()">
                                Abonar
                            </button>
                            <button type="button" class="btn btn-md text-white bg-danger" @click="backPage()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </main>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../../component/NavBar.vue';
import BarTop from './../../component/BarTop.vue';

import Vue3Datatable from "@bhplugin/vue3-datatable";
import NumUtils from '../../component/NumUtils.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DropFile,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop,
        NumUtils,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            verClientSupply: false,
            verShipping: false,
            shippings: [],
            verBillNote: false,
            billNotes: [],
            clientName: '',
            supply: [
            ],
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            paymentMethodes: [

            ],
            selectedAccount: null,
            banks: [],

            idClient: 0,
            newBank: false,
            dataForm3: {
                exchangeRate: {
                    value: 1,
                    err: false,
                },
                valueMoney: {
                    value: '',
                    err: false,
                    value_formated: '',
                }
            },
            dataForm2: {

                documentNumber: {
                    value: '',
                    err: false,
                },
                fileerr: false,
                files: [],
                fileName: "",
                fileBlob: null,
                mime_type: ""
            },
            dataForm: {
                sending: false,
                isSupply: false,
                payment: {},
                date: {
                    value: '',
                    err: false,
                },
                mount: {
                    value: '', // monto que efectivamente se envía a guardar
                    value_formated: '', // monto que se muestra por pantalla (input)
                    err: false,
                },
                id_client: {
                    value: 1,
                    err: false,
                },
                currenci: {
                    value: 15,
                    err: false,
                },
                accountOrigin: {
                    value: '',
                    err: false,
                },
                paymentMethod: {
                    value: 1,
                    err: false,
                },
                bank: {
                    value: 1,
                    err: false,
                },
                description: {
                    value: '',
                    err: false,
                },
                id_payment: 0,
                unpaid_amount: 0
            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);

        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');

        if (parts.length == 5) {
            if (!isNaN(Number(parts[3]))) {
                this.id_payment = Number(parts[3]);
                this.dataForm.id_payment = this.id_payment
                this.getPayment(this.id_payment);
            }
            if (!isNaN(Number(parts[4]))) {
                this.idClient = Number(parts[4]);
                this.getClient(this.idClient);

                this.getBank(this.idClient);
            }
        }
        this.getBanks();
        this.getSupply();
        this.getShipping();
        this.getBillNotes();
        this.getCurrencies();
        this.paymentMethodes = [{
            id: 1,
            name: 'Transferencia electronica ',

        }]
    },
    computed: {

    },
    methods: {
        deleteArchive() {
            const self = this;
            self.$swal.fire({
                icon: "question",
                title: "Estas seguro que deseas quitar este archivo adjunto?",
                showConfirmButton: true,
                confirmButtonText: "Si, eliminar",
                cancelButtonText: "No, conservar",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.dataForm2.fileName = "";
                    this.dataForm2.fileBlob = null;
                    this.dataForm2.files = [];
                    this.dataForm2.files.splice(0); // borrar todo
                    //this.dataForm.newBill = true;
                }
            });
        },
        setAccountDetails() {
            if (this.selectedAccount) {
                // Configura las variables con los valores seleccionados
                this.dataForm.bank.value = this.selectedAccount.id_bank;
                this.dataForm.accountOrigin.value = this.selectedAccount.account_number;
                console.log('Banco:', this.dataForm.bank.value);
                console.log('Número de cuenta:', this.dataForm.accountOrigin.value);
            }
        },
        async getBank(id_client) {

            try {
                const response = await axios.get('/api/getClientBank/' + id_client, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response.data);
                this.clientBank = response.data;
                console.log(this.clientBank.length == 0);
                if (this.clientBank.length == 0) {
                    this.newBank = true;
                    console.log(this.newBank)
                    this.$swal.fire({
                        icon: "info",
                        title: "No tienes cuentas bancarias ingresadas, tendras que ingresarla de forma manual ",
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                } else {
                    this.selectedAccount = this.clientBank[0];
                    this.dataForm.bank.value = this.selectedAccount.id_bank;
                    this.dataForm.accountOrigin.value = this.selectedAccount.account_number;
                }
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        formatAmountAndUpdate(amountInput, currencySelect) {
            NumUtils.methods.formatAmountAndUpdate(amountInput, currencySelect);
            this.dataForm3.valueMoney.value_formated = this.dataForm.mount.value_formated;
            this.dataForm3.valueMoney.value = this.dataForm.mount.value;
        },
        getformatCurrency(amountInput, currencySelect) {
            if (amountInput != undefined) {
                let formateado = NumUtils.methods.formatAmount(amountInput, currencySelect);
                return formateado.value_formated
            }
            return ""
        },
        backPage() {
            window.history.back();
        },
        async getPayment(idPayment) {
            try {
                const response = await axios.get('/api/services-to-be-collected/' + idPayment, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response.data)
                this.dataForm.payment = response.data.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        isSelectedShipp(data) {
            return this.dataForm3.shippingSelect == data
        },
        toggleSelectionShipping(data) {

            if (this.dataForm3.shippingSelect == data) {
                this.dataForm3.isShipping = false;
                this.dataForm3.shippingSelect = {};
            } else {
                this.dataForm3.isShipping = true;
                this.dataForm3.shippingSelect = data;
            }

        },


        async getBillNotes() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10000
            }

            axios.post('/api/getBillingNotes', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {

                if (response.status == 200) {

                    self.billNotes = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getClient(id) {
            const self = this;
            try {
                const response = await axios.get('/api/clients/' + id, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                self.clientName = response.data.name;

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        async getShipping() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10000
            }

            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {

                if (response.status == 200) {

                    self.shippings = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getSupply() {
            const self = this;
            try {
                const response = await axios.get('/api/client-supplies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                self.supply = response.data;

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verClientSupply = false;
        },
        closeModal2() {
            const self = this;
            self.verShipping = false;
        },
        closeModal3() {
            const self = this;
            self.verBillNote = false;
        },
        async getBanks() {
            try {
                const response = await axios.get('/api/banks', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.banks = response.data;
            } catch (error) {
                console.error('Error fetching banks:', error);
            }
        },
        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data.filter((divisa) => {
                    return divisa.status == 1
                });
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        create() {
            const self = this;

            self.dataForm.id_client.value = self.idClient;
            self.dataForm.sending = true;


            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    // console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                }
            };

            Object.keys(this.dataForm).forEach(key => {
                if (typeof this.dataForm[key] === 'object' && 'value' in this.dataForm[key]) {
                    if (key == 'description') {
                        // observación es opcional
                    } else {
                        validateField(this.dataForm[key]);
                    }
                }
            });

            if (self.dataForm2.documentNumber.value == '') {
                self.dataForm2.documentNumber.err = true;
                errFound = true;
            } else {
                self.dataForm2.documentNumber.err = false;
            }

            if (self.dataForm2.files.length == 0) {
                self.dataForm2.fileerr = true;
                errFound = true;
            } else {
                self.dataForm2.fileerr = false;
            }


            Object.keys(this.dataForm3).forEach(key => {
                if (typeof this.dataForm3[key] === 'object' && 'value' in this.dataForm3[key]) {
                    validateField(this.dataForm3[key]);
                }
            });

            if (errFound) {
                return false
            }

            this.$swal({
                title: 'Creando abono...'
            });
            this.$swal.showLoading();
            const req = { form: self.dataForm, form2: this.dataForm2, form3: this.dataForm3 }

            axios.post('/api/createCsFeePayment',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status == 200 || response.status == 201) {
                        if (response.data.surplus > 0) {
                            this.$swal.fire({
                                icon: "info",
                                title:'Abono realizado',
                                text: `Has abonado mas del monto pendiente,ahora cuentas con un excedente de: ${response.data.surplus}`,
                                showConfirmButton: false,
                                showCancelButton: false
                            });
                        }
                        if (response.data.unpaid_amount > 0) {
                            this.$swal.fire({
                                icon: "info",
                                title:'Abono realizado',
                                text: `Tu abono se realizo con exito, tu monto pendiente es de: ${response.data.unpaid_amount}`,
                                showConfirmButton: false,
                                showCancelButton: false
                            });
                        }
                        if (response.data.unpaid_amount == 0 && response.data.surplus == 0) {
                            self.$swal.fire({
                                icon: "success",
                                title: "abono completado con exito",
                                showConfirmButton: true,
                                showCancelButton: false
                            }).then((result) => {
                                window.location.href = '../../../serviciosCobrar';
                            });
                        }

                        //self.$swal.close();
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error detectado, comuníquese con soporte ",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                }).catch(function (error) {

                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno detectado, comuníquese con soporte ",
                        showConfirmButton: false,
                        showCancelButton: false
                    });

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>