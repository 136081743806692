<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="">Cuentas por cobrar</h2>
                    <p>Lista de todos los pagos a proveedores y servicios pendientes de cobro.
                        Los pagos corresponden a los realizados a aduanas, navieras y administradores varios para
                        efectuar los despachos.
                        Los servicios son cobros por la gestión de los despachos.
                    </p>
                </div>
                <div>
                    <p>Filtros</p>
                    <div class="text-end">
                        <div class=" mt-4 mx-4">
                            <div class="text-end">
                                <a class="btn btn-success" @click="filter()">Filtrar</a>
                            </div>
                        </div>
                    </div>
                    <div class="m-3 row">
                        <div class="col-md-2 mb-4">
                            <label for="rut" class="form-label">Rut cliente</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }"
                                id="rut" placeholder="" v-model="dataForm.rut.value">
                            <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un
                                rut</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="shipping_number" class="form-label">Nro despacho</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.shipping_number.err }" id="shipping_number"
                                placeholder="" v-model="dataForm.shipping_number.value">
                            <span v-if="dataForm.shipping_number.err" class="text-danger">Debe ingresar un
                                número de despacho</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="bill_number" class="form-label">Nro nota cobro</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.bill_number.err }" id="bill_number" placeholder=""
                                v-model="dataForm.bill_number.value">
                            <span v-if="dataForm.bill_number.err" class="text-danger">Debe ingresar un
                                número de nota cobro</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="document_number" class="form-label">Nro de factura</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.document_number.err }" id="document_number"
                                placeholder="" v-model="dataForm.document_number.value">
                            <span v-if="dataForm.document_number.err" class="text-danger">Debe ingresar un
                                número de documento</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="document_type" class="form-label">Tipo</label>
                            <select class="form-select" placeholder="seleccione un tipo"
                                aria-label="Default select example" v-model="dataForm.id_table_type.value">
                                <option value="">Ver todos</option>
                                <option :value="1">Servicio por cobrar</option>
                                <option :value="2">Pago proveedor</option>
                            </select>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="amount" class="form-label">Monto deuda original</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                                id="amount" placeholder="" v-model="dataForm.amount.value">
                            <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un monto</span>
                        </div>

                    </div>
                    <div class="text-end mb-3">
                        <button class="btn btn-primary me-2" @click="exportToExcel">
                            <i class="bi bi-file-earmark-spreadsheet me-1"></i> Descargar Excel
                        </button>
                        <button class="btn btn-danger" @click="exportToPDF">
                            <i class="bi bi-file-earmark-pdf me-1"></i> Descargar PDF
                        </button>
                    </div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #amount="data">
                            <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.initials) : 0
                                }}</span>
                        </template>
                        <template #value_payment="data">
                            <span>{{ data.value.value_payment > 0 ? formatCurrency(data.value.value_payment,
            data.value.initials) : 0 }}</span>
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{ data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount,
            data.value.initials) : 0 }}</span>
                        </template>
                        <template #rut="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                        </template>
                        <template #table_type="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.id_table_meta_type == 1 ?
            'Servicio por cobrar' : 'Pago proveedor' }}</td>
                        </template>
                        <!-- Celda personalizada -->
                        <template #date="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.date }}</td>
                        </template>
                        <template #actions="data">
                            <div class="text-center">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Acciones
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li @click="showService(data.value)"><a class="dropdown-item" href="#"> <i
                                                    class="bi bi-eye px-2"></i>Ver</a></li>
                                        <li v-if="(data.value.unpaid_amount > 0 && data.value.id_table_meta_type == 2) || data.value.id_table_meta_type == 1"
                                            @click="addPartialPayment(data.value)"><a class="dropdown-item" href="#"> <i
                                                    class="bi bi-plus-circle px-2"></i>Abonar</a></li>
                                        <li @click="editPaymentOrCssFee(data.value)"><a class="dropdown-item" href="#">
                                                <i class="bi bi-plus-circle px-2"></i>Editar</a></li>
                                        <li @click="showHistorial(data.value);"><a class="dropdown-item" href="#"> <i
                                                    class="bi bi-card-list px-2"></i>Historial</a></li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Historial-->
        <div class="modal-mb" v-if="verHistorial">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class=" text-black col-11" id="exampleModalLabel">Historial de pagos</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="row mx-4">
                                <div class="col-md-4 mb-4" v-if="tipoHistory == 2">
                                    <label for="historialType" class="form-label">Tipo</label>
                                    <select class="form-select" placeholder="seleccione un tipo"
                                        aria-label="Default select example" v-model="historialType">
                                        <option value="">Ver todos</option>
                                        <option :value="2">pago a proveedor</option>
                                        <option :value="4">pago a proveedor (abono directo)</option>
                                        <option :value="3">pago a proveedor (abono por provisión)</option>
                                    </select>
                                </div>
                                <div class="col-md-4 mb-4" v-if="tipoHistory == 1">
                                    <label for="historialType" class="form-label">Tipo</label>
                                    <select class="form-select" placeholder="seleccione un tipo"
                                        aria-label="Default select example" v-model="historialType">
                                        <option value="">Ver todos</option>
                                        <option :value="1">Servicio por cobrar</option>
                                        <option :value="5">Servicio por cobrar (abono directo)</option>
                                        <option :value="6">Servicio por cobrar (abono por provisión)</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mt-4">
                                    <div class="text-end">
                                        <a class="btn btn-success" @click="filterHistory()">Filtrar</a>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <vue3-datatable :search="search" :rows="payments" :columns="columns2" order
                                    :sortable="true" :sortColumn="'ID'">
                                    <template #id_balance="data">
                                        <span v-if="data.value.id_balance">
                                            {{ data.value.id_balance }}
                                        </span>
                                        <span v-if="data.value.id_payment_balance">
                                            {{ data.value.id_payment_balance }}
                                        </span>
                                    </template>
                                    <template #amount="data">
                                        <span>{{
            formatCurrency(data.value.amount, data.value.initials) }}</span>
                                    </template>

                                    <template #value_payment="data">
                                        <span v-if="data.value.value_payment != 0">{{
            formatCurrency(data.value.value_payment, data.value.value_payment_initials)
        }}</span>
                                        <span v-else>{{
                formatCurrency(0.00, data.value.initials) }}</span>
                                    </template>

                                    <template #unpaid_amount="data">
                                        <span v-if="data.value.unpaid_amount != 0">{{
            formatCurrency(data.value.unpaid_amount, data.value.value_unpaid_initials)
        }}</span>
                                        <span v-else>{{
                formatCurrency(0.00, data.value.initials) }}</span>
                                    </template>
                                    <template #rut="row">
                                        <td style=" text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                                    </template>
                                    <!-- Celda personalizada -->
                                    <template #date="row">
                                        <td style="text-align: end; white-space: nowrap;">{{ row.value.date }}</td>
                                    </template>
                                    <template #select="data">
                                        <button @click="toggleSelectionBoard(data.value)">
                                            {{ isSelectedBoard(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>
        <!-- Ver -->
        <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Servicio por Cobrar</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal2()"
                            aria-label="Cerrar"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card p-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <b>ID</b>
                                    <p>{{ service.id_payment != 0 ? service.id_payment : service.id_cs_fee }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de despacho</b>
                                    <p>{{ service.id_shipping }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Estado</b>
                                    <p>{{ service.status_name }}</p>
                                </div>

                                <div class="col-md-4">
                                    <b>Fecha de creación</b>
                                    <p>{{ service.date }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>RUT del cliente</b>
                                    <p>{{ service.rut }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto de deuda original</b>
                                    <p>{{ service.amount > 0 ? formatCurrency(service.amount, service.initials) : 0 }}
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto deuda abonado</b>
                                    <p>{{ service.value_payment > 0 ? formatCurrency(service.value_payment,
            service.value_payment_initials) : 0 }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto impago</b>
                                    <p>{{ service.unpaid_amount > 0 ? formatCurrency(service.unpaid_amount,
            service.initials) :
                                        0 }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de factura</b>
                                    <p>{{ service.bill_number }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de despacho</b>
                                    <p>{{ service.shipping_number }}</p>
                                </div>

                            </div>
                            <br><br>
                            <h6 v-if="service.bank_account_origin">Datos del pago efectuado al momento de abonar</h6>
                            <br>
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Banco</b>
                                    <p>{{ service.bank }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Método de pago</b>
                                    <p>{{ service.payment_method == 1 ? 'Transferencia electrónica' : '' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de cuenta</b>
                                    <p>{{ service.bank_account_origin }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal2()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
/** Librerías para exportar Excel y PDF **/
import * as XLSX from 'xlsx';             // Para Excel
import { saveAs } from 'file-saver';      // Para forzar la descarga de archivos
import jsPDF from 'jspdf';                // Para PDF
import autoTable from 'jspdf-autotable';  // Para crear tablas en el PDF

export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            tipoHistory: 0,
            historialType: '',
            idHistory: 0,
            service: {},
            verModal: false,
            dataForm: {
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                bill_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                document_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                id_table_type: {
                    value: "",
                    err: false,
                    msjErr: ''
                },
                amount: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
            },
            currentItems: [],
            verHistorial: false,
            items: [],
            columns: [
                { field: "shipping_number", title: "Número Despacho" },
                { field: "date_origin", title: "Fecha" },
                { field: "week_number_origin", title: "Semana" },
                { field: "id_billing_note", title: "Nota de Cobro" },
                { field: "bill_number", title: "Número de Factura" },
                { field: "table_type", title: "Tipo" },
                { field: "status_name", title: "Estado" },
                { field: "rut", title: "RUT" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto deuda original" },
                { field: "value_payment", title: "Último monto abonado" },
                { field: "unpaid_amount", title: "Monto impago actual" },
                { field: "actions", title: "" }
            ],
            columns2: [
                { field: "id_balance", title: "ID" },
                { field: "shipping_number", title: "Nº despacho" },
                { field: "date", title: "Fecha" },
                { field: "week_number", title: "Número de semana" },
                { field: "table_type", title: "Tipo" },
                { field: "bill_number", title: "Nota de cobro" },
                { field: "rut", title: "RUT cliente" },
                { field: "status_name", title: "Estado" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto deuda original" },
                { field: "value_payment", title: "Monto abonado" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "bank_account_origin", title: "Cuenta de origen" },
                { field: "bank", title: "Banco" },
                { field: "payment_method", title: "Método de pago" }
            ]
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        editPaymentOrCssFee(data) {

            if (data["id_cs_fee"] != 0) {
                window.location.href = '/serviciosCobrar/' + data.id_cs_fee;
            } else {
                window.location.href = '/pagoProveedores/crear/' + data.id_payment;
            }
        },
        async addPartialPayment(data) {

            try {

                if (data.id_table_meta_type == 1) {
                    this.$swal({
                        title: "¿Deseas abonar este pago?",
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Abonar",
                        cancelButtonText: 'Cancelar',
                        denyButtonText: `Abonar desde provisiones`
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.location.href = 'serviciosCobrar/abonar/' + data.id_cs_fee + '/' + data.id_client;
                        } else if (result.isDenied) {
                            this.getSupply(data, 2)
                            window.location.href = 'serviciosCobrar/provisionabonar/' + data.id_cs_fee + '/' + data.id_client;
                        }
                    });

                } else {
                    let showBtn = true;
                    let nota = "";
                    if (data.emitted_notes == "") {
                        showBtn = false;
                        nota = "Pago sin nota de cobro emitida, ";
                        nota += " abonos directos deshabilitados.";
                    }
                    this.$swal({
                        title: "¿Deseas abonar este pago?",
                        text: nota,
                        showDenyButton: true,
                        showCancelButton: true,
                        showConfirmButton: showBtn,
                        confirmButtonText: "Abonar",
                        cancelButtonText: 'Cancelar',
                        denyButtonText: `Abonar desde provisiones`
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.location.href = 'pagoProveedores/abonar/' + data.id_payment + '/' + data.id_client;
                        } else if (result.isDenied) {
                           
                            this.getSupply(data, 1)
                        }
                    });
                }

            } catch (error) {
                console.error('Error fetching concepts:', error);
            }

        },
        async getSupply(data, tipo) {
            const self = this;
            try {
                const response = await axios.get('/api/clientSupply/' + data.id_client, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                let supply = response.data; // Array de objetos con el campo "amount"

                // Suma de todos los amounts (asegurándonos de que sean numéricos)
                let totalAmount = supply.reduce((acc, item) => {
                    return acc + Number(item.amount);
                }, 0);

                if (totalAmount > 0) {
                    if(tipo == 1){
                        window.location.href = 'pagoProveedores/provisionabonar/' + data.id_payment + '/' + data.id_client;
                    }else{
                        window.location.href = 'serviciosCobrar/provisionabonar/' + data.id_cs_fee + '/' + data.id_client;
                    }
                  
                } else {
                    // Muestra mensaje si no hay saldo
                    this.$swal.fire({
                        icon: 'info',
                        title: 'Sin saldo disponible',
                        text: 'No cuentas con saldo en tus provisiones',
                        confirmButtonText: 'OK'
                    });
                }

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        async filterHistory() {
            let id = this.idHistory;
            let self = this;
            self.verHistorial = false;
            this.$swal({
                title: 'Obteniendo historial de abonos'
            });
            this.$swal.showLoading();
            try {
                let params = {
                    tipo: this.historialType
                }
                if (this.tipoHistory == 1) {
                    const response = await axios.post('/api/services-history-collected/' + id, params, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    });
                    if (response) {
                        this.$swal.close();
                        self.verHistorial = true;
                    }
                    self.payments = response.data.data;
                } else {

                    const response = await axios.post('/api/getPayments/' + id, params, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    });
                    if (response) {
                        this.$swal.close();
                        self.verHistorial = true;
                    }
                    self.payments = response.data.data;
                }

            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        async showHistorial(value) {
            this.tipoHistory = value.id_table_meta_type;

            this.historialType = '';
            let params = {
                tipo: ''
            }
            try {

                if (value.id_table_meta_type == 1) {
                    this.idHistory = value.id_cs_fee;
                    const response = await axios.post('/api/services-history-collected/' + value.id_cs_fee, params, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    });
                    this.payments = response.data.data;

                } else {
                    this.idHistory = value.id_payment;
                    const response = await axios.post('/api/getPayments/' + value.id_payment, params, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    });
                    this.payments = response.data.data;

                }


                this.verHistorial = true;

            } catch (error) {
                console.error('Error fetching concepts:', error);
            }

        },
        showService(value) {
            this.verModal = true;
            this.service = value;
        },
        closeModal2() {
            this.verModal = false;
        },
        closeModal() {
            console.log("aca");
            this.verHistorial = false;
        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        filter() {
            const self = this;

            let params = {
                rut: this.dataForm.rut.value, tipo: this.dataForm.id_table_type.value, nroDespacho: this.dataForm.shipping_number.value,
                nroFactura: this.dataForm.document_number.value, monto: this.dataForm.amount.value, bill_number: this.dataForm.bill_number.value
            }
            axios.post('/api/getReceivablesFilter', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    self.currentItems = self.items;
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });



        },
        getAR() {
            this.$swal({
                title: 'Obteniendo cuentas por cobrar'
            });
            this.$swal.showLoading();
            const self = this;

            let params = {
                rut: this.dataForm.rut.value, tipo: null, nroDespacho: this.dataForm.shipping_number.value,
                nroFactura: this.dataForm.document_number.value, monto: this.dataForm.amount.value, bill_number: this.dataForm.bill_number.value
            }
            axios.post('/api/getReceivablesFilter', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    self.currentItems = self.items;
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        },
        // ================================
        //   EXPORTAR A EXCEL
        // ================================
        exportToExcel() {
            try {
                // 1) Preparamos la data en formato de Array de Arrays
                //    Para la cabecera, podemos usar los títulos de las columnas
                const header = this.columns
                    .filter(col => col.field !== 'actions') // si no quieres la columna de "Acciones"
                    .map(col => col.title);

                // 2) Mapeamos 'items' para obtener sólo los datos que se mostrarán en Excel
                //    Debes asegurarte de que en "col.field" esté la propiedad real del item
                const data = this.items.map(item => {
                    return this.columns
                        .filter(col => col.field !== 'actions')
                        .map(col => {
                            // 'col.field' es la key que usarás para extraer el valor
                            return item[col.field] ?? '';
                        });
                });

                // 3) Unimos cabecera y data
                const worksheetData = [header, ...data];

                // 4) Generamos la hoja
                const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'CuentasPorCobrar');

                // 5) Convertimos a binario y forzamos la descarga
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array'
                });
                const blob = new Blob([excelBuffer], {
                    type: 'application/octet-stream'
                });

                saveAs(blob, 'cuentasPorCobrar.xlsx');
            } catch (error) {
                console.error('Error exportando a Excel:', error);
            }
        },

        // ================================
        //   EXPORTAR A PDF
        // ================================
        exportToPDF() {
            try {
                // 1) Configuramos el documento
                const doc = new jsPDF({
                    orientation: 'landscape', // 'portrait' o 'landscape'
                    unit: 'pt',               // 'pt', 'mm', 'cm'
                    format: 'letter'          // 'letter', 'A4', etc.
                });

                // 2) Creamos la cabecera en base a las columnas
                const header = this.columns
                    .filter(col => col.field !== 'actions')
                    .map(col => col.title);

                // 3) Mapeamos los datos de items
                const data = this.items.map(item => {
                    return this.columns
                        .filter(col => col.field !== 'actions')
                        .map(col => item[col.field] ?? '');
                });

                // 4) Usamos autoTable para generar la tabla en PDF
                autoTable(doc, {
                    head: [header],
                    body: data,
                    startY: 20, // margen superior
                    margin: { left: 10, right: 10 },
                    headStyles: { fillColor: [52, 73, 94] }, // Ejemplo de estilo en cabecera
                });

                // 5) Guardamos el PDF (forzamos la descarga)
                doc.save('cuentasPorCobrar.pdf');
            } catch (error) {
                console.error('Error exportando a PDF:', error);
            }
        },
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}

.column-email {
    width: 100px;
}
</style>