<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.tag-pill {
    display: inline-flex;
    align-items: center;
    margin: 0 4px 4px 0;
    padding: 1px 8px;
    background-color: #6ccc8f;
    /* Color de fondo */
    border-radius: 16px;
    /* Bordes redondeados */
    font-size: 0.875rem;
}

.tag-pill .btn-close {
    background: transparent;
    border: none;
    margin-left: 8px;
    cursor: pointer;
    font-size: 1rem;
    margin: 0px 2px 8px;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Crear despachos</b></h2>

                    </div>
                    <div class="row pt-4 px-1">
                        <div class="col-3 mb-4">
                            <label for="client" class="form-label">Cliente</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.client.value">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="shipping" class="form-label">Número despacho</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.shipping.err }"
                                id="shipping" placeholder="" v-model="dataForm.shipping.value">
                            <span v-if="dataForm.shipping.err" class="text-danger">Debe ingresar un
                                número de despacho</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="billOfLoading" class="form-label">Numero BL</label>
                            <input type="number" class="form-control"
                                :class="{ 'border-danger': dataForm.billOfLoading.err }" id="billOfLoading"
                                placeholder="" v-model="dataForm.billOfLoading.value"
                                v-tooltip="'El Bill of Lading (BL) es un documento que demuestra el contrato de transporte...'">
                            <span v-if="dataForm.billOfLoading.err" class="text-danger">Debe ingresar un numero de
                                BL</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="invoice" class="form-label">Número factura</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.invoice.err }"
                                id="invoice" placeholder="" v-model="dataForm.invoice.value">
                            <span v-if="dataForm.invoice.err" class="text-danger">Debe ingresar un
                                número de despacho</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="booking" class="form-label">Número reserva</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.booking.err }"
                                id="booking" placeholder="" v-model="dataForm.booking.value">
                            <span v-if="dataForm.booking.err" class="text-danger">Debe ingresar un
                                número de reserva</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="operationType" class="form-label">Tipo operación</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.operationType.value">
                                <option value="Exportación">Exportación
                                </option>
                                <option value="Importación">Importación
                                </option>
                                <option value="Muestras">Muestras
                                </option>
                            </select>

                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="boardType" class="form-label">Tipo de embarque</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.boardType.value">
                                <option value="Terrestre">Terrestre
                                </option>
                                <option value="Marítimo">Marítimo
                                </option>
                                <option value="Aéreo">Aéreo
                                </option>
                            </select>

                        </div>
                        <!-- Puerto de embarque -->
                        <div class="col-md-3 mb-4">
                            <label for="portDeparture" class="form-label">Puerto de embarque</label>
                            <select class="form-select" v-model="dataForm.portDeparture.value">
                                <option v-for="port in filteredPorts" :key="port.id_port" :value="port.id_port">
                                    {{ port.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.portDeparture.err" class="text-danger">
                                Debe ingresar un puerto de embarque
                            </span>
                        </div>

                        <div class="col-md-3 mb-4">
                            <label for="customsBroker" class="form-label">Agente de aduana</label>
                            <select class="form-select" aria-label="Agente de aduana"
                                v-model="dataForm.customsBroker.value">
                                <optgroup label="Acción">
                                    <option value="add-new">Agregar nuevo agente de aduana</option>
                                </optgroup>
                                <optgroup label="Agentes de aduana">
                                    <option v-for="agente in customsBrokers" :value="agente.id_customs_broker">{{
            agente.name }}
                                    </option>
                                </optgroup>
                            </select>
                            <span v-if="dataForm.customsBroker.err" class="text-danger">Debe ingresar un agente de
                                aduana</span>
                        </div>

                        <div class="col-md-3 mb-4">
                            <label for="clientReference" class="form-label">Referencia cliente</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.clientReference.err }" id="clientReference"
                                placeholder="" v-model="dataForm.clientReference.value">
                            <span v-if="dataForm.clientReference.err" class="text-danger">Debe ingresar un cliente de
                                referencia</span>
                        </div>


                        <div class="col-md-3 mb-4">
                            <label for="destinationCountry" class="form-label">País de destino</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.destinationCountry.value">
                                <option value="">Seleccione un país</option>
                                <option v-for="country in countries" :key="country" :value="country">
                                    {{ country }}
                                </option>
                            </select>
                            <span v-if="dataForm.destinationCountry.err" class="text-danger">Debe ingresar un pais de
                                destino</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="portArrive" class="form-label">Puerto de destino</label>
                            <select class="form-select" aria-label="Puerto de destino"
                                v-model="dataForm.portArrive.value">
                                <optgroup label="Acción">
                                    <option value="add-new">Crear un nuevo puerto</option>
                                </optgroup>
                                <optgroup label="Puertos disponibles">
                                    <option v-for="port in ports" :value="port.id_port">{{ port.name }}
                                    </option>
                                </optgroup>
                            </select>
                            <span v-if="dataForm.portArrive.err" class="text-danger">Debe ingresar un puerto de
                                destino</span>
                        </div>
                        <hr>
                        <div class="col-md-3 mb-4">
                            <label for="typeConsig" class="form-label">Tipo consignatario</label>
                            <select class="form-select" :class="{ 'border-danger': dataForm.typeConsig.err }"
                                id="typeConsig" v-model="dataForm.typeConsig.value">
                                <!-- Opción por defecto (opcional) -->
                                <option value="" disabled>Seleccione un tipo</option>
                                <!-- Opciones del select -->
                                <option value="consignacion-libre">Consignación libre</option>
                                <option value="a-firme">A firme</option>
                            </select>
                            <span v-if="dataForm.typeConsig.err" class="text-danger">
                                Debe ingresar un consignatario
                            </span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="consig" class="form-label">Consignatario</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.consig.err }"
                                id="consig" placeholder="" v-model="dataForm.consig.value">
                            <span v-if="dataForm.consig.err" class="text-danger">Debe ingresar un consignatario</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="totalContainer" class="form-label">Cantidad de contenedores</label>
                            <input type="number" class="form-control"
                                :class="{ 'border-danger': dataForm.totalContainer.err }" id="totalContainer"
                                placeholder="" v-model="dataForm.totalContainer.value">
                            <span v-if="dataForm.totalContainer.err" class="text-danger">Debe ingresar una cantidad de
                                contenedores</span>
                        </div>


                        <div class="col-md-3 mb-4 d-none">
                            <label for="petitioner" class="form-label">Peticionario</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.petitioner.err }" id="petitioner" placeholder=""
                                v-model="dataForm.petitioner.value">
                            <span v-if="dataForm.petitioner.err" class="text-danger">Debe ingresar un
                                peticionario</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="date" class="form-label">Fecha emisión DUS</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                        </div>
                        <div class="col-md-3 mb-4 d-none">
                            <label for="sender" class="form-label">Remitente</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.sender.err }"
                                id="sender" placeholder="" v-model="dataForm.sender.value">
                            <span v-if="dataForm.sender.err" class="text-danger">Debe ingresar un remitente</span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="comodato" class="form-label">Comodato</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.comodato.err }"
                                id="comodato" placeholder="Ingrese tags separados por coma o Enter" v-model="tagInput"
                                @keyup.enter.prevent="agregarTag" @keyup="detectarComa" @blur="handlerBlur" />
                            <!-- Contenedor que muestra las etiquetas generadas -->
                            <div class="mt-2">
                                <span v-for="(tag, index) in dataForm.comodato.tags" :key="index" class="tag-pill">
                                    {{ tag }}
                                    <button type="button" class="btn-close" @click="eliminarTag(index)">
                                        <!-- Una X o ícono para eliminar -->
                                        <span class="mb-2">x</span>
                                    </button>
                                </span>
                            </div>
                            <p v-if="dataForm.comodato.err" class="text-danger">
                                Debe ingresar al menos un tag
                            </p>
                        </div>
                        <div class="col-md-6">
                            <label for="description" class="form-label">Observación</label>
                            <textarea type="text" class="form-control" :disabled="isTextareaDisabled"
                                @focus="initializeTextarea" :class="{ 'border-danger': dataForm.description.err }"
                                id="description" placeholder="Ingrese un mensaje" v-model="dataForm.description.value">
                            </textarea>
                            <p v-if="dataForm.description.err" class="text-danger">Debe ingresar una
                                observacion</p>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="file" class="form-label mb-0">Adjuntar DUS</label>
                            <DropFile @drag-end="updateFileBlob" :files="dataForm.files" />
                            <div class="col-12 mt-4">
                                <div v-for="file in dataForm.files">
                                    <div class="text-dark row">
                                        <a class="col-6">
                                            {{ file.name }}
                                        </a>
                                        <div class="col-1" @click="showModal = true"><a
                                                class="dropdown-item text-primary" href="#"> <i
                                                    class="bi bi-eye px-2"></i></a></div>
                                        <div class="col-1" @click="deleteArchive(file)"><a
                                                class="dropdown-item text-danger" href="#"> <i
                                                    class="bi bi-trash px-2"></i></a></div>
                                    </div>

                                    <PreviewFile v-if="showModal" :blob="dataForm.fileBlob"
                                        :mimeType="dataForm.mime_type" :visible="showModal"
                                        @close="showModal = false" />
                                </div>
                            </div>
                        </div>

                        <hr>
                        <label for="file" class="form-label mb-0">Embarques</label>
                        <div class="col-md-12 px-2 mx-3 row p-3 text-center" style='border:2px solid black'>
                            <div class="col-md-6 pt-2">
                                <div class="col-md-6" v-if="dataForm.isBoard && !newBoard">

                                    <p>Este despacho esta asociado a un embarque: {{
            dataForm.boardSelect.boarding_number
        }}</p>
                                </div>
                                <button type="button" class="btn btn-primary" @click="newBoardFunc"
                                    v-if="!dataForm.isBoard">Crear un embarque
                                    nuevo</button>
                            </div>

                            <div class="col-md-5 pt-2">
                                <button type="button" class="btn btn-primary"
                                    @click="verBoarding = true; newBoard = false">Asociar/Desasociar
                                    embarque existente</button>
                            </div>
                            <br>
                            <br>
                            <p class="mt-4" v-if="newBoard">Creación de nuevo embarque</p>
                            <div class="col-md-3 mb-4 mt-4" v-if="newBoard">
                                <label for="origin" class="form-label">Puerto de embarque</label>
                                <input type="text" class="form-control" disabled
                                    :class="{ 'border-danger': dataForm2.origin.err }" id="origin" placeholder=""
                                    v-model="dataForm2.origin.value">
                                <span v-if="dataForm2.origin.err" class="text-danger">Debe ingresar un
                                    origen</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4" v-if="newBoard">
                                <label for="boarding_number" class="form-label">Número embarque</label>
                                <input type="text" class="form-control" disabled
                                    :class="{ 'border-danger': dataForm2.boarding_number.err }" id="boarding_number"
                                    placeholder="" v-model="dataForm2.boarding_number.value">
                                <span v-if="dataForm2.boarding_number.err" class="text-danger">Debe ingresar un
                                    número embarque</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4" v-if="newBoard">
                                <label for="destination" class="form-label">Puerto destino</label>
                                <input type="text" class="form-control" disabled
                                    :class="{ 'border-danger': dataForm2.destination.err }" id="destination"
                                    placeholder="" v-model="dataForm2.destination.value">
                                <span v-if="dataForm2.destination.err" class="text-danger">Debe ingresar un
                                    destino</span>
                            </div>
                            <div class="col-md-3 mb-4 mt-4" v-if="newBoard">
                                <label for="actual_delivery_date" class="form-label">Fecha estimada de envio</label>

                                <div class="input-group mb-3">
                                    <VueDatePicker v-model="dataForm2.actual_delivery_date.value" locale="es" disabled
                                        format="dd/MM/yyyy">
                                    </VueDatePicker>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4" v-if="newBoard">
                                <label for="estimated_delivery_date" class="form-label">Fecha estimada de envio</label>

                                <div class="input-group mb-3">
                                    <VueDatePicker v-model="dataForm2.estimated_delivery_date.value" locale="es"
                                        format="dd/MM/yyyy">
                                    </VueDatePicker>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4" v-if="newBoard">
                                <label for="id_transport_company" class="form-label">Compañia de transporte</label>
                                <select class="form-select" aria-label="Default select example"
                                    v-model="dataForm2.id_transport_company.value">
                                    <option v-for="transport in transports" :value="transport.id_transport_company">{{
            transport.name }}
                                    </option>
                                </select>
                            </div>


                        </div>
                    </div>
                    <div class="text-center text-lg-end mt-4 pt-2">
                        <button type="button" class="btn btn-md text-white mx-2"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                            @click="create()">
                            Crear
                        </button>
                        <button type="button" class="btn btn-md text-white bg-danger" @click="cancelBtn()"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                            Cancelar
                        </button>
                    </div>

                </div>
            </div>
        </main>

        <!-- Seleccionar embarque-->
        <div class="modal-mb" v-if="verBoarding">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Embarques</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">
                                <vue3-datatable :search="search" :rows="board" :columns="columns" order :sortable="true"
                                    :sortColumn="'date'">
                                    <template #select="data">
                                        <button @click="toggleSelectionBoard(data.value)">
                                            {{ isSelectedBoard(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import PreviewFile from './../component/PreviewFile.vue';
import countriesA from '@/country.js';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        PreviewFile,
        NavBar,
        BarTop,
        DropFile,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            companies: [

            ],
            clients: [

            ],
            showModal: false,
            portsAereo: [
                { id_port: 5, name: "Aeropuerto Arturo Merino Benitez" }
            ],
            portsTerrestre: [
                { id_port: 1, name: "Los Andes" },
                { id_port: 2, name: "Pino Achao" }
            ],
            // Supongamos que esta lista se llena dinámicamente o está hardcodeada
            portsMaritimo: [
                { id_port: 3, name: "Valparaíso" },
                { id_port: 4, name: "San Antonio" }
            ],
            countries: countriesA,
            verBoarding: false,
            columns: [

                { field: "boarding_number", title: "Número embarque" },
                { field: "ship_name", title: "Nombre Vessel" },
                { field: "origin", title: "Origen" },
                { field: "destination", title: "Destino" },
                { field: "actual_delivery_date", title: "Fecha actual de despacho" },
                { field: "estimated_delivery_date", title: "Fecha estimada de despacho" },
                { field: 'select', title: 'Seleccionar' },
            ],
            board: [],
            transports: [],
            ports: [],
            customsBrokers: [],
            idClient: 0,
            newBoard: false,
            dataForm2: {
                id_transport_company: {
                    value: 0,
                    err: false,
                    msjErr: ''
                }, origin: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, boarding_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, destination: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, estimated_delivery_date: {
                    value: '',
                    err: false,
                    msjErr: ''
                }, actual_delivery_date: {
                    value: '',
                    err: false,
                    msjErr: ''
                }
            },
            dataForm: {
                sending: false,
                client: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                invoice: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                booking: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                operationType: {
                    value: 'Exportación',
                    err: false,
                    msjErr: ''
                },
                boardType: {
                    value: 'Marítimo',
                    err: false,
                    msjErr: ''
                },
                aduana: {
                    value: 'no aplica',
                    err: false,
                    msjErr: ''
                },
                //agentAduana: { // formato antiguo
                //    value: '',
                //    err: false,
                //    msjErr: ''
                //},
                customsBroker: { // agente aduana
                    value: 0,
                    err: false,
                    msjErr: ''
                },
                clientReference: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                //portShipping: { // formato antiguo
                //    value: '',
                //    err: false,
                //    msjErr: ''
                //},
                //destinationPort: { // formato antiguo
                //    value: '',
                //    err: false,
                //    msjErr: ''
                //},
                portDeparture: {
                    value: 0, // el id del puerto
                    err: false,
                    msjErr: ''
                },
                portArrive: {
                    value: 0, // el id del puerto
                    err: false,
                    msjErr: ''
                },

                destinationCountry: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                consig: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                typeConsig: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                totalContainer: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
                billOfLoading: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },

                petitioner: {
                    value: 'no aplica',
                    err: false,
                    msjErr: ''
                },
                date: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                boardSelect: {},
                isBoard: false,
                sender: {
                    value: 'no aplica',
                    err: false,
                    msjErr: ''
                },
                comodato: {
                    // Aquí guardas al final la cadena CSV.
                    value: "",
                    // Aquí tienes el array de etiquetas.
                    tags: [],
                    // Flag para error o validaciones.
                    err: false
                },
                description: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                files: [],
                filerr: false,
                fileName: "",
                fileBlob: null,
                mime_type: ""

            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.idClient = this.user.company.id;
        const today = this.getTodayDate();
        this.dataForm2.actual_delivery_date.value = today;
        this.dataForm2.estimated_delivery_date.value = today;
        this.dataForm.date.value = today;
        this.getBoards();
        this.getTransports();
        this.getClients();
        this.getPorts();
        this.getCustomsBrokers();

    },
    watch: {
        'dataForm.portArrive.value': function (newVal) {
            if (newVal === 'add-new') {
                this.crearNuevoPuerto();
            }
        },
        'dataForm.customsBroker.value': function (newVal) {
            if (newVal === 'add-new') {
                this.crearNuevoAgenteAduana();
            }
        },
    },



    computed: {
        deleteArchive() {
            const self = this;
            self.$swal.fire({
                icon: "question",
                title: "Estas seguro que deseas quitar este archivo adjunto?",
                showConfirmButton: true,
                confirmButtonText: "Si, eliminar",
                cancelButtonText: "No, conservar",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.dataForm.fileName = "";
                    this.dataForm.fileBlob = null;
                    this.dataForm.files = [];
                    this.dataForm.files.splice(0); // borrar todo
                    //this.dataForm.newBill = true;
                }
            });
        },
        newBoardFunc() {
            const self = this;
            self.newBoard = true;
            self.dataForm2.boarding_number = self.dataForm.booking;
            self.dataForm2.destination = self.dataForm.portArrive;
            self.dataForm2.origin = self.dataForm.portDeparture;
            self.dataForm2.actual_delivery_date = self.dataForm.date;
        },
        isTextareaDisabled() {
            return (
                !this.dataForm.booking.value ||
                !this.dataForm.totalContainer.value ||
                !this.dataForm.clientReference.value ||
                !this.dataForm.comodato.value
            );
        },
        /**
    * Retorna el arreglo de puertos en función del tipo de embarque seleccionado.
    */
        filteredPorts() {
            const tipo = this.dataForm.boardType.value ? this.dataForm.boardType.value.toLowerCase() : '';

            switch (tipo) {
                case "aéreo":
                    return this.portsAereo;
                case "terrestre":
                    return this.portsTerrestre;
                case "marítimo":
                    return this.portsMaritimo;
                default:
                    return []; // En caso de que no haya nada seleccionado
            }
        }
    },
    methods: {
        updateFileBlob() {
            let newFile = this.dataForm.files[0];
            this.dataForm.fileName = newFile["name"];
            this.dataForm.mime_type = newFile["type"];
            this.dataForm.fileBlob = this.dataForm.files[0];
            this.dataForm.filerr = false;
        },
        crearNuevoPuerto() {
            // Dulce popup para preguntar el nombre del nuevo puerto
            this.$swal.fire({
                title: 'Crear nuevo puerto',
                text: 'Ingresa el nombre del nuevo puerto',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Crear',
                cancelButtonText: 'Cancelar',
                inputValidator: (value) => {
                    if (!value || !value.trim()) {
                        return 'Debes ingresar un nombre válido';
                    }
                    return null;
                }
            }).then((result) => {
                // Verificamos si el usuario confirmó y pasó la validación
                if (result.isConfirmed && result.value) {
                    const nuevoNombre = result.value.trim();
                    // Hacemos la llamada para guardar el nuevo puerto
                    this.guardarNuevoPuerto(nuevoNombre);
                } else {
                    // El usuario canceló o cerró el SweetAlert => reseteamos el select
                    this.dataForm.portArrive.value = "";
                }
            });
        },
        guardarNuevoPuerto(nombre) {
            // Aquí envías la solicitud a tu endpoint Laravel para crear el puerto
            // Por ejemplo, usando Axios:
            axios.post('/api/ports', { name: nombre }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(response => {
                    // Supongamos que el backend retorna el nuevo puerto con su id
                    // Algo como { id_port: 99, name: "Puerto Nuevo" }
                    const nuevoPuerto = response.data;

                    // Agregamos el nuevo puerto a la lista
                    this.ports.push(nuevoPuerto);

                    // Seleccionamos automáticamente el nuevo puerto
                    this.dataForm.portArrive.value = nuevoPuerto.id_port;
                })
                .catch(error => {
                    console.error(error);
                    this.$swal.fire('Error', 'No se pudo crear el puerto', 'error');
                    // Resetea el select si hubo error
                    this.resetPortArrive();
                });
        },
        crearNuevoAgenteAduana() {
            // Dulce popup para preguntar el nombre del nuevo agente de aduana
            this.$swal.fire({
                title: 'Agregar nuevo agente de aduana',
                text: 'Ingresa el nombre del nuevo agente de aduana',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Agregar',
                cancelButtonText: 'Cancelar',
                inputValidator: (value) => {
                    if (!value || !value.trim()) {
                        return 'Debes ingresar un nombre válido';
                    }
                    return null;
                }
            }).then((result) => {
                // Verificamos si el usuario confirmó y pasó la validación
                if (result.isConfirmed && result.value) {
                    const nuevoNombre = result.value.trim();
                    // Hacemos la llamada para guardar el nuevo puerto
                    this.guardarNuevoAgenteAduana(nuevoNombre);
                } else {
                    // El usuario canceló o cerró el SweetAlert => reseteamos el select
                    // this.resetPortArrive();
                }
            });
        },
        guardarNuevoAgenteAduana(nombre) {
            // Aquí envías la solicitud a tu endpoint Laravel para crear el puerto
            // Por ejemplo, usando Axios:
            axios.post('/api/customs-brokers', { name: nombre }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(response => {

                    const nuevoAgente = response.data.data;

                    // Agregar nuevo agente a la lista
                    this.customsBrokers.push(nuevoAgente);

                    // Seleccionamos el nuevo agente
                    this.dataForm.customsBroker.value = nuevoAgente.id_customs_broker;
                })
                .catch(error => {
                    console.error(error);
                    this.$swal.fire('Error', 'No se pudo agregar el agente de aduana', 'error');
                    // Resetea el select si hubo error
                    // this.resetPortArrive();
                });
        },

        getTodayDate() {
            const today = new Date();
            return today.toISOString().split("T")[0]; // Formato ISO para compatibilidad
        },
        cancelBtn() {
            window.location.href = '../../despachos'
        },
        initializeTextarea() {
            if (!this.isTextareaDisabled && !this.dataForm.description.value) {
                this.dataForm.description.value = `${this.dataForm.booking.value} // ${this.dataForm.totalContainer.value} // ${this.dataForm.clientReference.value} // ${this.dataForm.comodato.value}`;
            }
        },

        async getPorts() {
            try {
                const response = await axios.get('/api/ports', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.ports = response.data;
                //console.log(this.board)
            } catch (error) {
                console.error('Error fetching ports:', error);
            }
        },

        async getCustomsBrokers() {
            try {
                const response = await axios.get('/api/customs-brokers', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.customsBrokers = response.data;
                //console.log(this.board)
            } catch (error) {
                console.error('Error fetching customs-brokers:', error);
            }
        },

        async getTransports() {
            try {
                const response = await axios.get('/api/transportCompany', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.transports = response.data;
                console.log(this.board)
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        getClients() {
            const self = this;
            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    self.clients = response.data.filter((client) => client.status == 1);
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },
        async getBoards() {

            try {
                const response = await axios.get('/api/boardings', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.board = response.data;
                console.log(this.board)
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verBoarding = false;
        },
        isSelectedBoard(data) {
            return this.dataForm.boardSelect == data
        },
        toggleSelectionBoard(data) {
            const self = this;
            self.verBoarding = false;
            if (self.dataForm.boardSelect.id_boarding == data.id_boarding) {
                this.$swal.fire({
                    icon: "warning",
                    title: "Estas seguro que deseas desasociar este embarque?",
                    descripcion: "para actualizar este despacho necesitas tener asociado un embarque",
                    showConfirmButton: true,
                    confirmButtonText: "Si, desasociar",
                    showCancelButton: true,
                    cancelButtonText: "No, cancelar"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.dataForm.isBoard = false;
                        self.dataForm.boardSelect = {};
                    } else {
                        self.verBoarding = true
                    }
                });

            } else {
                this.$swal.fire({
                    icon: "warning",
                    title: "Estas seguro que deseas asociar este embarque?",
                    showConfirmButton: true,
                    confirmButtonText: "Si, asociar",
                    showCancelButton: true,
                    cancelButtonText: "No, cancelar"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.dataForm.isBoard = true;
                        self.dataForm.boardSelect = data;
                    } else {
                        self.verBoarding = true
                    }
                });

            }
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        agregarTag() {
            // Agregamos tag si no está vacío
            const nuevoTag = this.tagInput.trim();
            if (nuevoTag !== "") {
                this.dataForm.comodato.tags.push(nuevoTag);
            }
            // Limpiamos el input
            this.tagInput = "";
            // Opcional: actualizas inmediatamente dataForm.comodato.value
            this.actualizarComodatoValue();
        },
        detectarComa(event) {
            // Si el usuario escribe una coma, agregamos el tag
            if (event.key === ",") {
                event.preventDefault();
                const nuevoTag = this.tagInput.replace(/,/g, "").trim();
                if (nuevoTag !== "") {
                    this.dataForm.comodato.tags.push(nuevoTag);
                }
                this.tagInput = "";
                // Opcional: actualizas inmediatamente dataForm.comodato.value
                this.actualizarComodatoValue();
            }
        },
        eliminarTag(index) {
            this.dataForm.comodato.tags.splice(index, 1);
            // Opcional: vuelves a actualizar la cadena CSV
            this.actualizarComodatoValue();
        },
        handlerBlur() {
            // Ejemplo de validación si necesitas forzar al menos un tag
            if (this.dataForm.comodato.tags.length === 0) {
                this.dataForm.comodato.err = true;
            } else {
                this.dataForm.comodato.err = false;
            }
            // Finalmente, actualizamos el value como CSV
            this.actualizarComodatoValue();
        },
        actualizarComodatoValue() {
            // Transforma el array de tags en un CSV (separado por comas)
            this.dataForm.comodato.value = this.dataForm.comodato.tags.join(",");
        },
        create() {
            const self = this;

            self.dataForm.sending = true;
            self.actualizarComodatoValue();
            let errFound = false;
            const validateField = (field) => {
                if (field.value === '' || field.value === 0 || !field.value) {
                    field.err = true;
                    field.msjErr = 'Este campo es obligatorio';
                    console.log(field)
                    errFound = true;
                } else {
                    field.err = false;
                    field.msjErr = '';
                }
            };

            if (Object.keys(self.dataForm.boardSelect).length === 0 && !self.newBoard) {
                this.$swal.fire({
                            icon: "warning",
                            title: "No hay un embarque asociado al despacho",
                        });

                self.dataForm.boardSelectErr = true;
                errFound = true;
            } else {
                self.dataForm.boardSelectErr = false;
                errFound = false;
            }
            if (self.newBoard) {
                // Validar campos en dataForm2
                Object.keys(this.dataForm2).forEach(key => {
                    if (typeof this.dataForm2[key] === 'object' && 'value' in this.dataForm2[key]) {

                        validateField(this.dataForm2[key]);

                    }
                });
            }
            Object.keys(this.dataForm).forEach(key => {
                console.log(key);
                if (key != 'fileBlob') {
                    if (typeof this.dataForm[key] === 'object' && 'value' in this.dataForm[key]) {
                        validateField(this.dataForm[key]);

                    }
                }

            });
            // Verificar si hay errores
            if (errFound) {
                console.log(errFound)
                return false
            }

            this.$swal({
                title: 'Creando shipping...'
            });
            this.$swal.showLoading();
            const req = { form: self.dataForm, form2: this.dataForm2, newBoard: this.newBoard, idUser: this.user.id_user }
            axios.post('/api/createShipping',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status == 200) {

                        this.$swal.close();
                        this.$swal.fire({
                            icon: "success",
                            title: "Despacho creado con exíto",
                            showConfirmButton: true,
                            confirmButtonText: "Administrar paquetes",
                            showCancelButton: true,
                            cancelButtonText: "Cerrar sin paquetes"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '../../despachos/package/' + response.data.id_shipping
                            } else {
                                window.location.href = '../../despachos';
                            }
                        });
                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>