<style scoped>
.tabs {
  display: flex;

  margin-bottom: 0px;
}

button {
  padding: 10px 15px;
  border: none;
  background: #ddd;
  cursor: pointer;
}

button.active {
  background: #007bff;
  color: white;
}

.tab-content {
  padding: 10px;
  border: 1px solid #ddd;
}

.list-group-item2 {
  position: relative;
  /* display: block; */
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
  <div class="app-layout">
    <!-- Componente de la barra de navegación -->
    <NavBar
      :collapsed="collapsed"
      :pinned="pinned"
      @update:collapsed="updateCollapsed"
      @update:pinned="updatePinned"
    />
    <!-- Contenido principal -->

    <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
      <BarTop />
      <div class="pt-2 px-3">
        <div class="py-4">
          <h2 class="">Estado de cuenta</h2>
          <p>
            Listar todos los estados de cuenta de los clientes ingresados en el sistema.
          </p>
        </div>
        <p>Filtros</p>
        <div class="m-3 row">
          <div class="col-4 mb-4">
            <label for="client" class="form-label">Seleccionar cliente</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="dataForm.client.value"
            >
              <option :value="''">Seleccione un cliente</option>
              <option v-for="client in clients" :value="client.id_client">
                {{ client.name }}
              </option>
            </select>
            <span v-if="dataForm.client.err" class="text-danger"
              >Debe seleccionar un cliente</span
            >
          </div>
          <div class="col-4 mb-4">
            <label for="client" class="form-label">Tipo de consulta</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="dataForm.tipo.value"
            >
              <option value="1">Monto impago</option>
              <option value="2">Excedentes</option>
            </select>
          </div>
          <div class="col-md-2 mt-4">
            <div class="text-end">
              <a class="btn btn-success" @click="changeClient()">Filtrar</a>
            </div>
          </div>
        </div>
        <div class="tabs" v-if="showtable != 0">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ active: activeTab === tab.id }"
            @click="filter(tab.id)"
          >
            {{ tab.name }}
          </button>
        </div>
        <div v-if="showtable === 0" class="text-center text-secondary">
          <p>No hay registros para este cliente.</p>
        </div>
        <div class="tab-content" v-if="showtable != 0">
          <div>
            <div>
              <h5 class="text-black" v-if="dataForm.tipo.value == 1">
                Monto impago total: {{ totalUnpaidAmount() }}
              </h5>
              <h5 class="text-black" v-if="dataForm.tipo.value == 2">
                Monto excedente total: {{ totalOverPaidAmount() }}
              </h5>
            </div>
            <div class="text-end mb-3">
              <button class="btn btn-primary me-2" @click="exportToExcel">
                <i class="bi bi-file-earmark-spreadsheet me-1"></i> Descargar Excel
              </button>
              <button class="btn btn-danger" @click="exportToPDF">
                <i class="bi bi-file-earmark-pdf me-1"></i> Descargar PDF
              </button>
            </div>
            <div>
              <vue3-datatable
                :search="search"
                :rows="unpaidAcc"
                :columns="columns2"
                order
                :sortable="true"
                :sortColumn="'MONTO'"
              >
                <template #MONTO="data">
                  <span>{{
                    data.value.MONTO > 0
                      ? formatCurrency(data.value.MONTO, data.value.initials)
                      : 0
                  }}</span>
                </template>
                <template #ABONO="data">
                  <span>{{
                    data.value.MONTO > 0
                      ? formatCurrency(data.value.ABONO, data.value.initials)
                      : 0
                  }}</span>
                </template>
                <template #SALDO="data">
                  <span>{{
                    data.value.SALDO > 0
                      ? formatCurrency(data.value.SALDO, data.value.initials)
                      : 0
                  }}</span>
                </template>
                <template #EXCEDENTE="data">
                  <span>{{
                    data.value.EXCEDENTE > 0
                      ? formatCurrency(data.value.EXCEDENTE, data.value.initials)
                      : 0
                  }}</span>
                </template>
              </vue3-datatable>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from "./../component/NavBar.vue";
import BarTop from "./../component/BarTop.vue";
/** Librerías para exportar Excel y PDF **/
import * as XLSX from "xlsx"; // Para Excel
import { saveAs } from "file-saver"; // Para forzar la descarga de archivos
import jsPDF from "jspdf"; // Para PDF
import autoTable from "jspdf-autotable"; // Para crear tablas en el PDF
export default {
  components: {
    VueDatePicker,
    Vue3Datatable,
    NavBar,
    BarTop,
  },
  data() {
    return {
      collapsed: true,
      verModal: false,
      showtable: 0,
      tabs: [],
      activeTab: 0,
      pinned: localStorage.getItem("pinned") == "false" ? false : true || false,
      theme: localStorage.getItem("theme") || "light-mode",
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },

      unpaidAcc: [],
      columns2: [
        { field: "EMISION", title: "Emisión" },
        { field: "FACTURA", title: "Factura" },
        { field: "N_COBRO", title: "Nota cobro" },
        { field: "MONTO", title: "Monto" },
        { field: "ABONO", title: "Abono" },
        { field: "initials", title: "Divisa" },
        { field: "SALDO", title: "Saldo" },
        { field: "EXCEDENTE", title: "Excedente" },
      ],
      dataForm: {
        client: {
          value: "",
          err: false,
          msjErr: "",
        },
        tipo: {
          value: 1,
          err: false,
          msjErr: "",
        },
      },
      clients: [],
    };
  },
  mounted() {
    this.getClients();
    document.body.className = this.theme;
  },
  computed: {},
  methods: {
    formatCurrency(value, currencyCode = "CLP") {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0, // O puedes cambiar el valor según lo que necesites
      }).format(value);
    },
    totalUnpaidAmount() {
      // Calcula el total de los valores de SALDO
      let total = this.unpaidAcc.reduce((total, item) => {
        // Asegúrate de que el campo SALDO es un número válido
        const saldo = parseFloat(item.SALDO) || 0;
        return total + saldo;
      }, 0);

      console.log(total);

      return this.formatNumber(total); // Opcional: Formatear el resultado con 2 decimales
    },
    totalOverPaidAmount() {
      // Calcula el total de los valores de EXCEDENTE
      let total = this.unpaidAcc.reduce((total, item) => {
        // Asegúrate de que el campo SALDO es un número válido
        const excedente = parseFloat(item.EXCEDENTE) || 0;
        return total + excedente;
      }, 0);

      console.log(total);

      return this.formatNumber(total); // Opcional: Formatear el resultado con 2 decimales
    },
    formatNumber(value) {
      return value.toLocaleString("es-ES"); // Formato con separadores para español
    },
    async changeClient() {
      await this.getCurrencyUnpaid(this.dataForm.client.value, this.dataForm.tipo.value);
    },
    async filter(id_tab) {
      const self = this;

     

      self.activeTab = id_tab;
      const selectedCurrency = this.tabs.find((tab) => tab.id === id_tab).id_currency;
      let req = {
        page: 1,
        id_currency: selectedCurrency,
        per_page: 1000,
        id_client: this.dataForm.client.value,
        type: this.dataForm.tipo.value,
      };
      axios
        .post("/api/balanceView", req, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.status == 200) {
            console.log(response);
            if (response.data.data && response.data.data.length > 0) {
              this.unpaidAcc = response.data.data;
               self.showtable = 1;
            } else {
              self.unpaidAcc = [];
              this.$swal("No hay registros para este cliente", "", "info");
              // Mostramos la tabla
              self.showtable = 0;
            }

            //self.clientesSelect = response.data.clienteEmpresa;
          
          } else {
            self.unpaidAcc = [];
             this.$swal("No hay registros para este cliente", "", "info");
            // Mostramos la tabla
            this.showtable = 0;
          }
        })
        .catch(function (error) {
          // Verificamos si existe error.response
          if (error.response && error.response.statusText === "Unauthorized") {
            localStorage.removeItem("accessToken");
            window.location.href = "./../../api/auth/logout";
          } else {
            // Manejo de otros errores
            console.error(error);
          }
        });
    },
    async getCurrencyUnpaid(id_client, id_tipo) {
      if(id_client == ""){
        this.$swal("Debe seleccionar un cliente", "", "info");
        return
      }
      let req = {
        id_client: id_client,
        type: id_tipo,
      };
      axios
        .post("/api/getUnpaidSummary", req, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.status == 200) {
            if (response.data.divisas && response.data.divisas.length > 0) {
              // Generamos las tabs
              this.tabs = response.data.divisas.map((currency, index) => ({
                id: index + 1, // Genera un ID basado en la posición
                name: `${currency.name} (${currency.initials})`, // Combina el nombre e iniciales
                id_currency: currency.id_currency, // Conserva el ID original
              }));

              // Activamos la primera pestaña
              this.activeTab = this.tabs[0].id;

           

              // Cargamos los datos de la primera divisa seleccionada
              this.filter(this.activeTab);
            } else {
              // No hay divisas, por lo tanto no hay tabs
              this.tabs = [];
              this.activeTab = 0;
              this.showtable = 0; // Podrías usar esto para ocultar la sección de la tabla

              // Muestra un mensaje al usuario
              // O bien un simple alert, un mensaje en pantalla, o un modal de SweetAlert
              // Ejemplo con SweetAlert:
              this.$swal("No hay registros para este cliente", "", "info");
            }
          }
        })
        .catch(function (error) {
          // Verificamos si existe error.response
          if (error.response && error.response.statusText === "Unauthorized") {
            localStorage.removeItem("accessToken");
            window.location.href = "./../../api/auth/logout";
          } else {
            // Manejo de otros errores
            console.error(error);
          }
        });
    },
    updateCollapsed(value) {
      this.collapsed = value; // Actualiza el estado
    },
    updatePinned(value) {
      this.pinned = value; // Actualiza el estado
    },
    showBill(data) {
      this.verModal = true;
      this.billNote = data;
    },
    getClients() {
      this.$swal({
        title: "Obteniendo clientes",
      });
      this.$swal.showLoading();
      const self = this;

      axios
        .get("/api/clients", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.status == 200) {
            self.clients = response.data;

            //self.clientesSelect = response.data.clienteEmpresa;
            this.$swal.close();
          } else {
            self.clients = [];
          }
        })
        .catch(function (error) {
          // Verificamos si existe error.response
          if (error.response && error.response.statusText === "Unauthorized") {
            localStorage.removeItem("accessToken");
            window.location.href = "./../../api/auth/logout";
          } else {
            // Manejo de otros errores
            console.error(error);
          }
        });
    },
    // ================================
    //   EXPORTAR A EXCEL
    // ================================
    exportToExcel() {
      try {
        // 1) Preparamos la data en formato de Array de Arrays
        //    Para la cabecera, podemos usar los títulos de las columnas
        const header = this.columns2
          .filter((col) => col.field !== "actions") // si no quieres la columna de "Acciones"
          .map((col) => col.title);

        // 2) Mapeamos 'items' para obtener sólo los datos que se mostrarán en Excel
        //    Debes asegurarte de que en "col.field" esté la propiedad real del item
        const data = this.unpaidAcc.map((item) => {
          return this.columns2
            .filter((col) => col.field !== "actions")
            .map((col) => {
              // 'col.field' es la key que usarás para extraer el valor
              return item[col.field] ?? "";
            });
        });

        // 3) Unimos cabecera y data
        const worksheetData = [header, ...data];

        // 4) Generamos la hoja
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "EstadoCuenta");

        // 5) Convertimos a binario y forzamos la descarga
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        saveAs(blob, "EstadoCuenta.xlsx");
      } catch (error) {
        console.error("Error exportando a Excel:", error);
      }
    },

    // ================================
    //   EXPORTAR A PDF
    // ================================
    exportToPDF() {
      try {
        // 1) Configuramos el documento
        const doc = new jsPDF({
          orientation: "landscape", // 'portrait' o 'landscape'
          unit: "pt", // 'pt', 'mm', 'cm'
          format: "letter", // 'letter', 'A4', etc.
        });

        // 2) Creamos la cabecera en base a las columnas
        const header = this.columns2
          .filter((col) => col.field !== "actions")
          .map((col) => col.title);

        // 3) Mapeamos los datos de items
        const data = this.unpaidAcc.map((item) => {
          return this.columns2
            .filter((col) => col.field !== "actions")
            .map((col) => item[col.field] ?? "");
        });

        // 4) Usamos autoTable para generar la tabla en PDF
        autoTable(doc, {
          head: [header],
          body: data,
          startY: 20, // margen superior
          margin: { left: 10, right: 10 },
          headStyles: { fillColor: [52, 73, 94] }, // Ejemplo de estilo en cabecera
        });

        // 5) Guardamos el PDF (forzamos la descarga)
        doc.save("EstadoCuenta.pdf");
      } catch (error) {
        console.error("Error exportando a PDF:", error);
      }
    },
  },
};
</script>

<style lang="css">
@import "bootstrap";
@import "datatables.net-bs5";

body {
  background-color: #f2f5f9;
}

.cont-proveedor {
  height: 300px;
  overflow-y: scroll;
}
</style>
